var React = require('react');
var Bootstrap = require('react-bootstrap');

var Navbar = Bootstrap.Navbar;
var NavBrand = Bootstrap.NavBrand;
var Nav = Bootstrap.Nav;
var NavItem = Bootstrap.NavItem;
var Image = Bootstrap.Image;

var Navigation = React.createClass({

	render: function() {
		return (
			<Navbar fixedTop toggleNavKey={0}>
				<NavBrand>
					<a href="#">
						<Image alt="ss" src="images/talumbon.png"/>
					</a>
				</NavBrand>
				<Nav right eventKey={0}> {/* This is the eventKey referenced */}
					<NavItem eventKey={1} href="#aboutus">ABOUT US</NavItem>
					<NavItem eventKey={2} href="#ourservices">OUR SERVICES</NavItem>
					<NavItem eventKey={3} href="#portfolio">PORTFOLIO</NavItem>
					<NavItem eventKey={4} href="#contact">CONTACT</NavItem>
				</Nav>
			</Navbar>
		);
	}
});

module.exports = Navigation;
