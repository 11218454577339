var React = require('react');
var Slider = require('react-slick');
var Bootstrap = require('react-bootstrap');

var Image = Bootstrap.Image;

var carousel = React.createClass({

	render: function() {
		var settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [{
				breakpoint: 1024,
				settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: true
				}
			}, {
				breakpoint: 600,
				settings: {
						slidesToShow: 1,
						slidesToScroll: 1
				}
			}, {
				breakpoint: 480,
				settings: {
						slidesToShow: 1,
						slidesToScroll: 1
				}
			}]
		};
		return (
			<div>
				<h2 className="text-center">Our Services</h2>
				<Slider {...settings}>
					<div className="col-sm-12 col-md-12">
						<div className="thumbnail">
							<Image src="/images/businessicon_normal.png" alt="PROJECT" />
							<div className="caption">
								<h3 className="text-center">BUSINESS</h3>
								<p>
									We help business owner defined the needs to take a business to the next level, define a scope, cost and a time-frame of the project and to support business from the very beginning of the project till the end, and deliver the project not only the scope, time and cost but also with complete customer satisfaction.
								</p>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-12">
						<div className="thumbnail">
							<Image src="/images/projecticon_normal.png" alt="PROJECT" />
							<div className="caption">
								<h3 className="text-center">PROJECT</h3>
								<p>
									Project scoping and planing, Talumbon help business owner know the detail of what the project is going to deliver until it starts the process. And to prevent project significant financial loss.
								</p>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-12">
						<div className="thumbnail">
							<Image src="/images/process_normal.png" alt="PROJECT" />
							<div className="caption">
								<h3 className="text-center">PROCESS</h3>
								<p>
									Business process and system design, Talumbon help business link from scope of a project to the proposed business processes an systems that the project is going to delivery. Regardless of whether the project is to launch a new project range or discontinue unprofitable parts of the business, the change will have some impact on business processes and system. We believe the documentation of your business processes and system requirements are as fundamental to project scoping as an architects plan.
								</p>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-12">
						<div className="thumbnail">
							<Image src="/images/mgmicon_normal.png" alt="PROJECT" />
							<div className="caption">
								<h3 className="text-center">MANAGEMENT</h3>
								<p>
									Project management support, Talumbon with project leadership role and significant business change projects/programs, outside expertise will bring business owner realise the changes from day-to-day operations from employee who has the authority, vision and influence to drive the required changes in a business.
								</p>
							</div>
						</div>
					</div>
				</Slider>
			</div>
		);
	}

});

module.exports = carousel;