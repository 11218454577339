var React = require('react');
var Carousel = require('./carousel.jsx');

var ourservices = React.createClass({

	render: function() {
		return (
			<section id="ourservices" className="ourservices">
				<div className="container">
					<div className="row">
						<Carousel />
					</div>
				</div>
			</section>
		);
	}

});

module.exports = ourservices;