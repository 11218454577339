var React = require('react');
var Bootstrap = require('react-bootstrap');
var Image = Bootstrap.Image;


var portfolio = React.createClass({

	render: function() {
		return (
			<section id="portfolio" className="portfolio">
				<h2 className="text-center">PORTFOLIO</h2>
				<blockquote>
					<div className="row">
						<div className="col-xs-12 col-md-4 col-md-offset-2">
							<Image src="/images/portfolio.png" alt="portfolio" />
						</div>
						<div className="col-xs-12 col-md-4 text-left">
							<h3> กดดูรู้สิทธิ</h3>
							<p>โครงการมีจุดประสงค์ นําเสนอการพัฒนา ระบบ การเข้าถึงข้อมูลและเปิดให้บริการ โปรแกรมประยุกต์บนอุปกรณ์มือถือ ที่ สามารถเสริมการบริการปกติของทางหน่วย งานภาครัฐ ที่ให้บริการทางเว็ปไซต์ เพื่อเชื่อม ข้อมูลจากหน่วยงานต่างๆ และนําเสนอข้อมูล ต่อประชาชนให้สามารถใช้งาน ได้ง่าย เข้าใจ ง่าย เป็นส่วนหนึ่งในความพยายามลดความเห ลื่อมล้ําในสังคม และเป็นการบูรณาการข้อมูล สิทธิของผู้ด้อยโอกาสให้สามารถ เข้าถึง รับรู้ และเข้าใจได้ง่าย อีกทั้งประชาชนทั่วไป สามารถเข้าถึงข้อมูลด้านสิทธิเพื่อช่วยเหลือ ค้ําจุนกันในชุมชน</p>
								<div className="col-xs-6 col-sm-6">
									<a href="https://itunes.apple.com/th/app/kd-du-ru-siththi/id1050866391?mt=8" target="_blank">
										<Image src="/images/p1-btn-ios.png" className="img-responsive" width="100%" />
									</a>
								</div>
								<div className="col-xs-6 col-sm-6">
									<a href="https://play.google.com/store/apps/details?id=com.lazcode.humanrights" target="_blank">
										<Image src="/images/p1-btn-gg.png" className="img-left img-responsive" width="100%" />
									</a>
								</div>
						</div>
					</div>
				</blockquote>
			</section>
		);
	}

});

module.exports = portfolio;