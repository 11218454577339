var React = require('react');

var aboutus = React.createClass({

	render: function() {
		return (
			<section className="aboutus" id="aboutus">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-lg-offset-2 text-center">
							<h2 className="section-heading">ABOUTUS</h2>
							<hr className="light" />
							<p className="text-faded">
								Talumbon is an Information Technology consulting services firm specialising in serving information business with living edge technology. Talumbon offers a wide range of Information Technology from estimate, manage, implement, deploy and administer Information Technology system.
							</p>
						</div>
					</div>
				</div>
			</section>
		);
	}

});

module.exports = aboutus;