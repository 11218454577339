var React = require('react');

var footer = React.createClass({

	render: function() {
		return (
			<footer className="site-footer">
				<div className="container">
					<div className="row">
						<div className="col-sm-6">
							<h5>TALUMBON © 2015</h5></div>
						<div className="col-sm-6 social-icons"><a href="#"><span className="fa fa-facebook"></span></a><a href="#"><span className="fa fa-twitter"></span></a><a href="#"><span className="fa fa-instagram"></span></a></div>
					</div>
				</div>
				<div></div>
			</footer>
		);
	}

});

module.exports = footer;