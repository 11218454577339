var React = require('react');

var introheader = React.createClass({

	render: function() {
		return (
			<div className="jumbotron hero">
				<div className="container">
					<div className="row">
						<div className="col-md-12 get-it">
							<h1>WHO ARE WE?</h1>
							<p>Talumbon is an information technology consulting and services</p>
						</div>
					</div>
				</div>
			</div>
		);
	}

});

module.exports = introheader;