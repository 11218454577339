var React = require('react');

var contact = React.createClass({

	render: function() {
		return (
			<section id="contact" className="contact">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-lg-offset-2 text-center">
							<h2 className="section-heading">Lets Get In Touch!</h2>
							<hr className="primary" />
							<p>Ready to start your next project with us? Thats great! Give us a call or send us an email and we will get back to you as soon as possible!</p>
						</div>
						<div className="col-lg-4 text-center">
							<i className="fa fa-phone fa-3x wow bounceIn telno"></i>
							<p> +66 8 1438 7229</p>
						</div>
						<div className="col-lg-4 text-center">
							<i className="fa fa-location-arrow fa-3x wow bounceIn telno"></i>
							<p>36/50 m8 Bangkhen, Maung Nonthaburi Nonthaburi, Thailand 11000</p>
						</div>
						<div className="col-lg-4 text-center">
							<i className="fa fa-envelope-o fa-3x wow bounceIn mailto" data-wow-delay=".1s"></i>
							<p><a href="mailto:tlb@talumbon.com">tlb@talumbon.com</a></p>
						</div>
					</div>
				</div>
			</section>
		);
	}

});

module.exports = contact;