var React = require('react');
var Bootstrap = require('react-bootstrap');

var Navigation = require('./navigation.jsx');
var IntroHeader = require('./introheader.jsx');
var AboutUs = require('./aboutus.jsx');
var OurServices = require('./ourservices.jsx');
var Portfolio = require('./portfolio.jsx');
var Footer = require('./footer.jsx');
var Contact = require('./contact.jsx');

var Main = React.createClass({

	render: function () {
		return (
			<div>
				<Navigation />
				<IntroHeader />
				<AboutUs />
				<OurServices />
				<Portfolio />
				<Contact />
				<Footer />
			</div>
		);
	}

});

module.exports = Main;